import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css' // Ensure this import is included if not already
import Cringe from './components/Cringe'
import Bingo from './components/bingo/Bingo'

const App: React.FC = () => {
    return (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Router>
                {/* <Navbar />
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/cv" element={<CV />} />
            <Route path="/imprint" element={<Imprint />} />
          </Routes>
        </div>
        <Footer /> */}
                <Cringe />
                <Bingo />
            </Router>
        </div>
    )
}

export default App
