import React, { useState, useEffect } from 'react'

const Cringe: React.FC = () => {
    const calculateTimeUntil = () => {
        const timeUntil =
            new Date('2024-10-04T00:00:00').getTime() - new Date().getTime()
        const days = Math.floor(timeUntil / (1000 * 60 * 60 * 24))
        const hours = Math.floor(
            (timeUntil % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        )
        const minutes = Math.floor((timeUntil % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((timeUntil % (1000 * 60)) / 1000)
        const milliseconds = timeUntil % 1000

        return { days, hours, minutes, seconds, milliseconds }
    }

    const [timeUntil, setTimeUntil] = useState(calculateTimeUntil())

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeUntil(calculateTimeUntil())
        }, 1) // Update every millisecond

        return () => clearInterval(interval)
    }, [])

    return (
        <div className="cringe-container" id="cringe">
            <h1>Time until we can hold hands</h1>
            <p>
                Only<br></br>
                {timeUntil.days} days<br></br>
                {timeUntil.hours}hours,<br></br>
                {timeUntil.minutes} minutes,<br></br>
                {timeUntil.seconds} seconds, and<br></br>
                {timeUntil.milliseconds} milliseconds left!
            </p>
        </div>
    )
}

export default Cringe
