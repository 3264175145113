import React, { useState, useEffect } from 'react'
import './Bingo.css'

type BingoCell = {
    id: number
    content: string
    isSelected: boolean
}

type BingoState = {
    freedBoard: BingoCell[]
    haileyBoard: BingoCell[]
    winner: string | null
    isPlaying: boolean
}

const Bingo: React.FC = () => {
    const [gameState, setGameState] = useState<BingoState | null>(null)
    const [loggedInUser, setLoggedInUser] = useState<'Freed' | 'Hailey' | null>(
        null,
    )
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState('')

    // Function to fetch the game state from the server
    const fetchGameState = () => {
        fetch('https://backend.konstantin-schuetz.de/game-state')
            .then((res) => res.json())
            .then((data) => {
                setGameState(data)
            })
    }

    // Fetch the initial game state from the server when the app loads and poll every second
    useEffect(() => {
        fetchGameState() // Initial fetch
        const interval = setInterval(fetchGameState, 1000) // Fetch every second

        return () => clearInterval(interval) // Clean up on component unmount
    }, [])

    const updateGameState = (newState: BingoState) => {
        fetch('https://backend.konstantin-schuetz.de/game-state', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newState),
        }).then(() => {
            setGameState(newState) // Update local state after syncing with server
        })
    }

    // Reset board logic
    const resetBoard = () => {
        const initialState: BingoState = {
            freedBoard: Array.from({ length: 25 }, (_, id) => ({
                id,
                content: '',
                isSelected: false,
            })),
            haileyBoard: Array.from({ length: 25 }, (_, id) => ({
                id,
                content: '',
                isSelected: false,
            })),
            winner: null,
            isPlaying: false, // Stop the game
        }

        updateGameState(initialState) // Send reset state to server
    }

    const handleClick = (id: number, board: 'Freed' | 'Hailey') => {
        if (
            loggedInUser === board && // Only allow the logged-in user to interact with their own board
            gameState &&
            !gameState.winner &&
            gameState.isPlaying
        ) {
            const updatedBoard =
                board === 'Freed'
                    ? gameState.freedBoard.map((cell) =>
                          cell.id === id
                              ? { ...cell, isSelected: !cell.isSelected }
                              : cell,
                      )
                    : gameState.haileyBoard.map((cell) =>
                          cell.id === id
                              ? { ...cell, isSelected: !cell.isSelected }
                              : cell,
                      )

            const updatedState: BingoState = {
                ...gameState,
                [board === 'Freed' ? 'freedBoard' : 'haileyBoard']:
                    updatedBoard,
            }

            updateGameState(updatedState)
        }
    }

    const handleContentChange = (
        id: number,
        newContent: string,
        board: 'Freed' | 'Hailey',
    ) => {
        if (gameState && !gameState.isPlaying && loggedInUser === board) {
            const updatedBoard =
                board === 'Freed'
                    ? gameState.freedBoard.map((cell) =>
                          cell.id === id
                              ? { ...cell, content: newContent }
                              : cell,
                      )
                    : gameState.haileyBoard.map((cell) =>
                          cell.id === id
                              ? { ...cell, content: newContent }
                              : cell,
                      )

            const updatedState: BingoState = {
                ...gameState,
                [board === 'Freed' ? 'freedBoard' : 'haileyBoard']:
                    updatedBoard,
            }

            updateGameState(updatedState)
        }
    }

    const handleLogin = () => {
        if (
            (username === 'Freed' || username === 'Hailey') &&
            password === 'love'
        ) {
            setLoggedInUser(username as 'Freed' | 'Hailey')
            setLoginError('')
        } else {
            setLoginError('Invalid username or password.')
        }
    }

    const startGame = () => {
        if (gameState) {
            const updatedState = { ...gameState, isPlaying: true }
            updateGameState(updatedState)
        }
    }

    if (!gameState) {
        return <div>Loading game state...</div>
    }

    if (!loggedInUser) {
        return (
            <div className="login-container">
                <h2>Login</h2>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button onClick={handleLogin}>Login</button>
                {loginError && <p className="error">{loginError}</p>}
            </div>
        )
    }

    return (
        <div>
            <div className="status">
                <h1>Kamala/Trump Bingo</h1>
                <h2>
                    {gameState.isPlaying
                        ? 'Game is in progress'
                        : 'Waiting to start'}
                </h2>
            </div>
            <div className="otherButtons">
                {!gameState.isPlaying && (
                    <button className="start-btn" onClick={startGame}>
                        Start Playing
                    </button>
                )}
                <button className="reset-btn" onClick={resetBoard}>
                    Reset Board
                </button>{' '}
            </div>
            <div className="board-container">
                <div className="board">
                    <h3>Freed's Board</h3>
                    <div className="bingo-grid">
                        {gameState.freedBoard.map((cell) => (
                            <div
                                key={cell.id}
                                className={`bingo-cell ${
                                    cell.isSelected ? 'selected' : ''
                                }`}
                                onClick={() => handleClick(cell.id, 'Freed')}
                                contentEditable={
                                    !gameState.isPlaying &&
                                    loggedInUser === 'Freed'
                                } // Only allow Freed to edit Freed's board
                                suppressContentEditableWarning={true}
                                onBlur={(e) =>
                                    handleContentChange(
                                        cell.id,
                                        e.target.innerText,
                                        'Freed',
                                    )
                                }
                                style={{
                                    pointerEvents:
                                        loggedInUser === 'Freed'
                                            ? 'auto'
                                            : 'none', // Disable clicking for Hailey on Freed's board
                                }}
                            >
                                {cell.content}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="board">
                    <h3>Hailey's Board</h3>
                    <div className="bingo-grid">
                        {gameState.haileyBoard.map((cell) => (
                            <div
                                key={cell.id}
                                className={`bingo-cell ${
                                    cell.isSelected ? 'selected' : ''
                                }`}
                                onClick={() => handleClick(cell.id, 'Hailey')}
                                contentEditable={
                                    !gameState.isPlaying &&
                                    loggedInUser === 'Hailey'
                                } // Only allow Hailey to edit Hailey's board
                                suppressContentEditableWarning={true}
                                onBlur={(e) =>
                                    handleContentChange(
                                        cell.id,
                                        e.target.innerText,
                                        'Hailey',
                                    )
                                }
                                style={{
                                    pointerEvents:
                                        loggedInUser === 'Hailey'
                                            ? 'auto'
                                            : 'none', // Disable clicking for Freed on Hailey's board
                                }}
                            >
                                {cell.content}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {gameState.winner && <h2>{gameState.winner} wins!</h2>}
        </div>
    )
}

export default Bingo
